import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import DanielImage from './../images/about-us/daniel.png'
import DoubleDanielImage from './../images/about-us/daniel@2x.png'
import SteveImage from './../images/about-us/steve.png'
import DoubleSteveImage from './../images/about-us/steve@2x.png'
import { TweenLite } from 'gsap/all'
import { animationType, buildScrollTriggers, createAnimationType, doAnimate, nl2br, resetElements } from '../utils/functions'
import Jumpbutton from '../components/ux/buttons/jumpbutton/jumpbutton'
import { ScrollTrigger } from 'gsap/all'
import Paradigm from '../components/ux/svg/paradigm'
import { connect } from 'react-redux'


function AboutUs({ translations, lang }) {
    const lightHolderRef = useRef();
    const swingerRef = useRef();

    const [rendered, setRendered] = useState(false)

    createAnimationType();

    // Scrollrefs
    const scrollRef1 = useRef();
    const scrollRef2 = useRef();
    const scrollRefs = [
        scrollRef1,
        scrollRef2
    ];

    // Animation Refs
    const imageRef = useRef();
    const imageRef2 = useRef();
    const imageRef3 = useRef();
    const h1Ref = useRef();
    const textRef = useRef();
    const h1Ref2 = useRef();
    const whoisRef1 = useRef();
    const whoisRef2 = useRef();
    const textRef2 = useRef();
    const textRef3 = useRef();

    // Aktuelle Position, Status etc.
    let currentScrollPos = useRef(1);
    let animationActive = useRef(false);
    let entered = useRef(false);
    let clickable = useRef(true);


    // Animationen für jede Section
    const scrollEffect = useCallback((step) => {
        var durationFactor = 1;
        var delayFactor = .5;
        animationActive.current = true;
        clickable.current = false;
        switch (step) {
            case 2:
                // Alte Szene ausblenden
                TweenLite.to(h1Ref.current, { x: -50, y: -150, autoAlpha: 0 });
                TweenLite.to(textRef.current, { x: -50, y: -100, autoAlpha: 0 });
                TweenLite.to(imageRef.current, { scale: .75, y: -50, autoAlpha: 0 });

                // Neue Szene einblenden
                TweenLite.from(h1Ref2.current, { y: -20, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(whoisRef1.current, { y: 50, x: -50, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(whoisRef2.current, { y: 50, x: 50, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(imageRef2.current, { y: 50, x: -50, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(imageRef3.current, { y: 50, x: 50, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(textRef2.current, { x: -50, y: 100, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.from(textRef3.current, { x: 50, y: 100, autoAlpha: 0, duration: durationFactor, delay: delayFactor });
                TweenLite.to(lightHolderRef.current, { xPercent: -50, ease: animationType, duration: 1.5 });
                TweenLite.to(swingerRef.current, {
                    xPercent: 80, rotation: 360, autoAlpha: 0, duration: 1.5, onComplete: () => {
                        resetElements([h1Ref.current, textRef.current, imageRef.current]);
                        clickable.current = true;
                    }
                });
                break;
            default: // Standard Introanimation
                if (entered.current === true) {
                    TweenLite.to(h1Ref2.current, { y: -20, autoAlpha: 0, duration: durationFactor });
                    TweenLite.to(whoisRef1.current, { y: 50, x: -50, autoAlpha: 0, duration: durationFactor });
                    TweenLite.to(whoisRef2.current, { y: 50, x: 50, autoAlpha: 0, duration: durationFactor });
                    TweenLite.to(imageRef2.current, { y: 50, x: -50, autoAlpha: 0, duration: durationFactor });
                    TweenLite.to(imageRef3.current, { y: 50, x: 50, autoAlpha: 0, duration: durationFactor });
                    TweenLite.to(textRef2.current, { x: -50, y: 100, autoAlpha: 0, duration: durationFactor });
                    TweenLite.to(textRef3.current, { x: 50, y: 100, autoAlpha: 0, duration: durationFactor });
                    TweenLite.to(lightHolderRef.current, { xPercent: 0, ease: animationType, duration: 1.5 });
                }
                TweenLite.from(h1Ref.current, { x: -150, y: -50, autoAlpha: 0, duration: 1 });
                TweenLite.from(textRef.current, { x: -150, y: 50, autoAlpha: 0, duration: 1 });
                TweenLite.from(imageRef.current, {
                    x: 150, autoAlpha: 0, ease: animationType, duration: 1, onComplete: () => {
                        animationActive.current = false;
                        if (entered.current === false) {
                            clickable.current = true;
                        }
                        entered.current = true;
                    }
                });
                if (entered.current) {// Zurückanimation
                    TweenLite.to(swingerRef.current, {
                        ease: animationType, xPercent: 0, rotation: 280, autoAlpha: 1, duration: 1.5, onComplete: () => {
                            clickable.current = true;
                            resetElements([h1Ref2.current, whoisRef1.current, whoisRef2.current, textRef2.current, textRef3.current, imageRef2.current, imageRef3.current]);
                        }
                    })
                } else { // Startanimation
                    TweenLite.set(swingerRef.current, { rotation: 280, y: 100 });
                    TweenLite.from(swingerRef.current, { ease: animationType, x: 300, autoAlpha: 0, duration: 1 })
                }
                break;


        }
    }, []);

    // Scrollt zur nächsten Section
    const scrollToRef = (ref) => {
        let offset = ref.current.offsetTop + 108;
        if (currentScrollPos.current === 2) { // Bis footer
            offset += 180;
        }
        TweenLite.to(window, { duration: 1.5, scrollTo: offset });

    };

    // Initialisierung
    useEffect(() => {
        if (doAnimate()) {
            document.body.classList.add('noscroll');
            TweenLite.defaultEase = animationType;
            if (rendered !== true) {
                setRendered(true)
                setTimeout(() => {
                    scrollEffect(1);
                    buildScrollTriggers(scrollRefs, scrollEffect, scrollToRef, currentScrollPos, animationActive);
                }, 500);
                setTimeout(() => {
                    document.body.classList.remove('noscroll');
                }, 2000)
            }
            return () => {
                ScrollTrigger.getAll().forEach(t => t.kill());
            }
        }
    }, [scrollEffect, scrollRefs, rendered])

    const clickHandler = () => {
        if (clickable.current === false || !doAnimate()) return
        currentScrollPos.current += 1
        scrollEffect(currentScrollPos.current);
        scrollToRef(scrollRefs[currentScrollPos.current - 1])
    }

    return (
        <div>
            <Layout>
                <SEO title={translations.about_us_title} description={translations.about_us_meta_description} />
                <div className="about-us subpage">
                    <div className="lightholder" ref={lightHolderRef}></div>
                    <section ref={scrollRef1}>
                        <div className="swinger" ref={swingerRef}></div>
                        <div className="inner-wrapper flex">
                            <div>
                                <div ref={h1Ref} className="animationElement">
                                    <h1>
                                        <span className="yellow">
                                            {
                                                nl2br(translations.about_us_1_yellow_headline)
                                            }
                                        </span>
                                        <br />
                                        {
                                            nl2br(translations.about_us_1_white_headline)
                                        }
                                    </h1>
                                    <p className="grey">
                                        {
                                            nl2br(translations.about_us_1_subline)
                                        }
                                    </p>
                                </div>
                                <div ref={textRef} className="animationElement">
                                    <p className="light">
                                        {
                                            nl2br(translations.about_us_1_description)
                                        }
                                    </p>
                                </div>
                            </div>
                            <div ref={imageRef} className="animationElement">
                                <Paradigm lang={lang} />
                            </div>
                        </div>
                    </section>
                    <section ref={scrollRef2}>
                        <div className="inner-wrapper">
                            <div>
                                <div ref={h1Ref2} className="text-center headline animationElement">
                                    <div className='bs-headline'>
                                        {
                                            nl2br(translations.about_us_management_white)
                                        }<br />
                                        <span className="yellow">
                                            {
                                                nl2br(translations.about_us_management_yellow)
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex team-info">
                                <div className="steve-image"><img className="no-scale person" src={SteveImage} srcSet={`${DoubleSteveImage} 2x`} alt="Steve" ref={imageRef2} /></div>
                                <div className="steve-info">
                                    <div className="whois" ref={whoisRef1}>
                                        <p className="yellow names">
                                            {
                                                nl2br(translations.about_us_2_yellow_headline)
                                            }
                                        </p>
                                        <p className="title">
                                            {
                                                nl2br(translations.about_us_2_white_headline)
                                            }
                                        </p>
                                        <p className="quote mb-2">
                                            {
                                                nl2br(translations.about_us_2_subline)
                                            }
                                        </p>
                                    </div>
                                    <div ref={textRef2}>
                                        <p className="light">
                                            {
                                                nl2br(translations.about_us_2_description)
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="daniel-image"><img className="no-scale person" src={DanielImage} alt="Daniel" srcSet={`${DoubleDanielImage} 2x`} ref={imageRef3} /></div>
                                <div className="daniel-info">
                                    <div className="whois" ref={whoisRef2}>
                                        <p className="yellow names">
                                            {
                                                nl2br(translations.about_us_3_yellow_headline)
                                            }
                                        </p>
                                        <p className="title">
                                            {
                                                nl2br(translations.about_us_3_white_headline)
                                            }
                                        </p>
                                        <p className="quote mb-2">
                                            {
                                                nl2br(translations.about_us_3_subline)
                                            }
                                        </p>
                                    </div>
                                    <div ref={textRef3}>
                                        <p className="light">
                                            {
                                                nl2br(translations.about_us_3_description)
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Jumpbutton clickHandler={clickHandler} />
            </Layout>
        </div>
    )
}



const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(AboutUs)
